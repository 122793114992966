import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useState, useMemo } from 'react';

interface CampaignFilterContext {
  statuses?: string[];
  dateLow?: string;
  dateHigh?: string;
}
const noFiltersApplied: CampaignFilterContext = {
  statuses: [],
  dateLow: undefined,
  dateHigh: undefined,
};

const CampaignFilterContext = createContext({
  campaignFilterValues: {} as CampaignFilterContext,
  setCampaignFilterValues: {} as Dispatch<SetStateAction<CampaignFilterContext>>,
});

export const useCampaignFilterContext = () => {
  const { campaignFilterValues, setCampaignFilterValues } = useContext(CampaignFilterContext);

  const [contextValue, setContextValue] = useState<CampaignFilterContext>({
    statuses: ['ACTIVE', 'UPCOMING'],
    dateLow: undefined,
    dateHigh: undefined,
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(campaignFilterValues, noFiltersApplied);
  }, [campaignFilterValues]);

  const handleClearFilters = () => {
    setCampaignFilterValues(noFiltersApplied);
  };
  const campaignDefaultValueForContextProvider = {
    campaignFilterValues: contextValue,
    setCampaignFilterValues: setContextValue,
  };

  return {
    campaignDefaultValueForContextProvider,
    campaignFilterValues,
    setCampaignFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    CampaignFilterContext,
  };
};
