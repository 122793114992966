import { ReactElement } from 'react';

import { useCampaignFilterContext } from 'src/features/campaigns/contexts/campaignFilterContext';
import { useMediaFilterContext } from 'src/features/mediaLibrary/contexts/mediaFilterContext';
import { usePlaylistFilterContext } from 'src/features/playlists/contexts/playlistFilterContext';
import { useUserFilterContext } from 'src/features/users/contexts/userFilterContext';

interface FiltersProviderProps {
  children: ReactElement[] | ReactElement;
}

export const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const { campaignDefaultValueForContextProvider, CampaignFilterContext } = useCampaignFilterContext();
  const { mediaDefaultValueForContextProvider, MediaFilterContext } = useMediaFilterContext();
  const { userDefaultValueForContextProvider, UserFilterContext } = useUserFilterContext();
  const { playlistDefaultValueForContextProvider, PlaylistFilterContext } = usePlaylistFilterContext();

  return (
    <MediaFilterContext.Provider value={mediaDefaultValueForContextProvider}>
      <CampaignFilterContext.Provider value={campaignDefaultValueForContextProvider}>
        <UserFilterContext.Provider value={userDefaultValueForContextProvider}>
          <PlaylistFilterContext.Provider value={playlistDefaultValueForContextProvider}>
            {children}
          </PlaylistFilterContext.Provider>
        </UserFilterContext.Provider>
      </CampaignFilterContext.Provider>
    </MediaFilterContext.Provider>
  );
};
