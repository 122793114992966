import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CampaignByIDResponse } from 'src/features/campaigns';
import { campaignGETByIDPath } from 'src/features/campaigns/api/paths';
import { httpClient } from 'src/lib/axios';

type GetCampaignByIdDTO = {
  data: {
    brandId: number | string;
    campaignId: number | string;
    queryParams?: {
      [key: string]: string;
    };
  };
};

const getCampaignById = async ({ data }: GetCampaignByIdDTO): Promise<CampaignByIDResponse> => {
  const { brandId, campaignId, queryParams } = data;

  const {
    data: { payload },
  } = await httpClient.get(`${campaignGETByIDPath(brandId, campaignId)}`, {
    params: queryParams,
  });

  return payload as CampaignByIDResponse;
};

export const constructGetCampaignByIdDTO = (
  brandId: string | number | undefined,
  campaignId: string | number | undefined,
  queryParams?: { [key: string]: string }
): GetCampaignByIdDTO => {
  return {
    data: {
      brandId: brandId as string | number,
      campaignId: campaignId as string | number,
      queryParams,
    },
  };
};

export const useGetCampaign = (payload: GetCampaignByIdDTO, options?: UseQueryOptions<CampaignByIDResponse>) => {
  const {
    data: { campaignId },
  } = payload;

  return useQuery<CampaignByIDResponse>({
    queryKey: ['campaign', +campaignId],
    queryFn: () => getCampaignById(payload),
    keepPreviousData: true,
    ...options,
  });
};
