import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { ReactElement, useEffect, useState } from 'react';

import { setAccessToken } from 'src/lib/axios';

interface AccessTokenProviderProps {
  children: ReactElement[] | ReactElement;
}

export const AccessTokenProvider = withAuthenticationRequired(({ children }: AccessTokenProviderProps): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const [tokenDefined, setTokenDefined] = useState<boolean>(false);

  useEffect(() => {
    const setAccessTokenOnHttpClient = async () => {
      const token = await getAccessTokenSilently();

      setAccessToken(token);
      setTokenDefined(true);
    };

    setAccessTokenOnHttpClient();
  }, [getAccessTokenSilently]);

  return tokenDefined ? <>{children}</> : <></>;
});
