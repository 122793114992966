import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { BrandResponse } from 'src/features/brand';
import { brandIdGETPath } from 'src/features/brand/api/paths';
import { httpClient } from 'src/lib/axios';

const getBrand = async (): Promise<BrandResponse> => {
  const {
    data: { payload },
  } = await httpClient.get(`${brandIdGETPath()}`);

  return payload as BrandResponse;
};

export const useGetBrand = (options?: UseQueryOptions<BrandResponse>) => {
  return useQuery<BrandResponse>({
    queryKey: ['brandMetadata'],
    queryFn: () => getBrand(),
    keepPreviousData: true,
    ...options,
  });
};
