import loadable from '@loadable/component';
import { Navigate, Outlet } from 'react-router-dom';

// Default level routes
const UserRoutes = loadable(() => import('src/features/users/routes'), {
  resolveComponent: components => components.UserRoutes,
});
const MediaRoutes = loadable(() => import('src/features/mediaLibrary/routes'), {
  resolveComponent: components => components.MediaRoutes,
});
const PlaylistRoutes = loadable(() => import('src/features/playlists/routes'), {
  resolveComponent: components => components.PlaylistRoutes,
});
const CampaignRoutes = loadable(() => import('src/features/campaigns/routes'), {
  resolveComponent: components => components.CampaignRoutes,
});

const App = () => {
  return <Outlet />;
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'users/*', element: <UserRoutes /> },
      { path: 'library/*', element: <MediaRoutes /> },
      { path: 'campaigns/*', element: <CampaignRoutes /> },
      { path: 'campaigns/:campaignId/playlist/*', element: <PlaylistRoutes /> },
      { path: '/', element: <Navigate to="campaigns" /> },
      { path: '/*', element: <Navigate to="campaigns" /> },
    ],
  },
];
