import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { UserRole } from 'src/features/users';

interface UserFilterContext {
  roles?: UserRole[];
}

const noFiltersApplied: UserFilterContext = {
  roles: [],
};

const UserFilterContext = createContext({
  userFilterValues: {} as UserFilterContext,
  setUserFilterValues: {} as Dispatch<SetStateAction<UserFilterContext>>,
});

export const useUserFilterContext = () => {
  const { userFilterValues, setUserFilterValues } = useContext(UserFilterContext);

  const [contextValue, setContextValue] = useState<UserFilterContext>({
    roles: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(userFilterValues, noFiltersApplied);
  }, [userFilterValues]);

  const handleClearFilters = () => {
    setUserFilterValues(noFiltersApplied);
  };

  const userDefaultValueForContextProvider = {
    userFilterValues: contextValue,
    setUserFilterValues: setContextValue,
  };

  return {
    userDefaultValueForContextProvider,
    userFilterValues,
    setUserFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    UserFilterContext,
  };
};
