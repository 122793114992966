import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

interface BrandContext {
  id: string;
  name?: string;
}

const BrandContext = createContext({
  activeBrand: {} as BrandContext,
  setActiveBrand: {} as Dispatch<SetStateAction<BrandContext>>,
});

export const useBrandContext = () => {
  const { activeBrand, setActiveBrand } = useContext(BrandContext);

  const [contextValue, setContextValue] = useState<BrandContext>({
    id: '',
    name: '',
  });

  const brandDefaultValueForContextProvider = {
    activeBrand: contextValue,
    setActiveBrand: setContextValue,
  };

  return {
    brandDefaultValueForContextProvider,
    activeBrand,
    setActiveBrand,
    BrandContext,
  };
};
