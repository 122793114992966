import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CollectionsIcon from '@mui/icons-material/Collections';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const listOfBasicRoutes = ['/campaigns', '/library', '/users'];

export const NavList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      {!listOfBasicRoutes.includes(pathname) ? (
        <List sx={{ backgroundColor: 'primary.main' }} component="nav">
          <ListItemButton onClick={() => navigate(-1)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Back" />
          </ListItemButton>
        </List>
      ) : null}
      <List sx={{ backgroundColor: 'primary.main' }} component="nav">
        <ListItemButton onClick={() => navigate('/campaigns')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <PlaylistAddIcon />
          </ListItemIcon>
          <ListItemText primary="Campaigns" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/library')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <CollectionsIcon />
          </ListItemIcon>
          <ListItemText primary="Library" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/users')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
      </List>
    </>
  );
};
