import { ReactElement } from 'react';

import { useCampaignContext } from 'src/contexts/activeCampaignContext';
import { useBrandContext } from 'src/contexts/brandContext';
import { usePageTitleContext } from 'src/contexts/pageTitleContext';

interface ContextProviderProps {
  children: ReactElement[] | ReactElement;
}

export const ContextProvider = ({ children }: ContextProviderProps) => {
  const { campaignDefaultValueForContextProvider, ActiveCampaignContext } = useCampaignContext();
  const { brandDefaultValueForContextProvider, BrandContext } = useBrandContext();
  const { pageTitleDefaultValueForContextProvider, PageTitleContext } = usePageTitleContext();

  return (
    <PageTitleContext.Provider value={pageTitleDefaultValueForContextProvider}>
      <BrandContext.Provider value={brandDefaultValueForContextProvider}>
        <ActiveCampaignContext.Provider value={campaignDefaultValueForContextProvider}>
          {children}
        </ActiveCampaignContext.Provider>
      </BrandContext.Provider>
    </PageTitleContext.Provider>
  );
};
