import { useLayoutEffect, useState } from 'react';

import { useCampaignContext } from 'src/contexts/activeCampaignContext';
import { constructGetCampaignByIdDTO, useGetCampaign } from 'src/features/campaigns/api/getCampaign';

export const useCampaignName = (brandId: string | undefined, campaignId: string | undefined): string => {
  const [campaignName, setCampaignName] = useState<string>('');
  const { activeCampaign, setActiveCampaign } = useCampaignContext();

  const { refetch } = useGetCampaign(constructGetCampaignByIdDTO(brandId, campaignId), {
    enabled: false,
    onSuccess: data => {
      setActiveCampaign({ id: `${data.id}`, name: data.name });
      setCampaignName(`${data.name}`);
    },
  });

  useLayoutEffect(() => {
    if (campaignId && brandId) {
      if (campaignId === activeCampaign.id) {
        setCampaignName(`${activeCampaign.name}`);
      } else {
        refetch();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return campaignName;
};
