import { ReactElement, useState } from 'react';

import { useBrandContext } from 'src/contexts/brandContext';
import { useGetBrand } from 'src/features/brand/api/getBrand';

interface BrandProviderProps {
  children: ReactElement[] | ReactElement;
}

export const BrandProvider = ({ children }: BrandProviderProps): JSX.Element | null => {
  const [brandIsDefined, setBrandIsDefined] = useState<boolean>(false);
  const { setActiveBrand } = useBrandContext();

  useGetBrand({
    onSuccess: data => {
      setActiveBrand({ id: `${data.id}`, name: `${data.name}` });
      setBrandIsDefined(true);
    },
  });

  return brandIsDefined ? <>{children}</> : <></>;
};
